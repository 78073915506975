"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "GEOLOCATION_ALLOWED", {
  enumerable: true,
  get: function get() {
    return _geoLocationStatus.GEOLOCATION_ALLOWED;
  }
});
Object.defineProperty(exports, "GEOLOCATION_COMING_SOON", {
  enumerable: true,
  get: function get() {
    return _geoLocationStatus.GEOLOCATION_COMING_SOON;
  }
});
Object.defineProperty(exports, "GEOLOCATION_NOT_ALLOWED", {
  enumerable: true,
  get: function get() {
    return _geoLocationStatus.GEOLOCATION_NOT_ALLOWED;
  }
});
Object.defineProperty(exports, "GEOLOCATION_USA", {
  enumerable: true,
  get: function get() {
    return _geoLocationStatus.GEOLOCATION_USA;
  }
});
Object.defineProperty(exports, "INPUT_LIMITS", {
  enumerable: true,
  get: function get() {
    return _inputVerification.INPUT_LIMITS;
  }
});
Object.defineProperty(exports, "X_GEO_COUNTRY", {
  enumerable: true,
  get: function get() {
    return _geoLocationHeaders.X_GEO_COUNTRY;
  }
});
Object.defineProperty(exports, "X_GEO_LOCATION_STATUS", {
  enumerable: true,
  get: function get() {
    return _geoLocationHeaders.X_GEO_LOCATION_STATUS;
  }
});
Object.defineProperty(exports, "calculateDiscount", {
  enumerable: true,
  get: function get() {
    return _api.calculateDiscount;
  }
});
Object.defineProperty(exports, "decodeBasicAuthentication", {
  enumerable: true,
  get: function get() {
    return _basicAuth.decodeBasicAuthentication;
  }
});
Object.defineProperty(exports, "determineArrivalDay", {
  enumerable: true,
  get: function get() {
    return _determineArrivalDay["default"];
  }
});
Object.defineProperty(exports, "encodeBasicAuthentication", {
  enumerable: true,
  get: function get() {
    return _basicAuth.encodeBasicAuthentication;
  }
});
Object.defineProperty(exports, "extractTextFromURL", {
  enumerable: true,
  get: function get() {
    return _api.extractTextFromURL;
  }
});
Object.defineProperty(exports, "formatPostcode", {
  enumerable: true,
  get: function get() {
    return _formatPostcode["default"];
  }
});
Object.defineProperty(exports, "gbOrXi", {
  enumerable: true,
  get: function get() {
    return _getCountryCode.gbOrXi;
  }
});
Object.defineProperty(exports, "getCountry", {
  enumerable: true,
  get: function get() {
    return _getCountry["default"];
  }
});
Object.defineProperty(exports, "getCountryCode", {
  enumerable: true,
  get: function get() {
    return _getCountryCode.getCountryCode;
  }
});
Object.defineProperty(exports, "getCurrencyCode", {
  enumerable: true,
  get: function get() {
    return _api.getCurrencyCode;
  }
});
Object.defineProperty(exports, "getCurrencyCodeByLocale", {
  enumerable: true,
  get: function get() {
    return _api.getCurrencyCodeByLocale;
  }
});
Object.defineProperty(exports, "getDateFromISO", {
  enumerable: true,
  get: function get() {
    return _api.getDateFromISO;
  }
});
Object.defineProperty(exports, "getDateWithZeroTime", {
  enumerable: true,
  get: function get() {
    return _api.getDateWithZeroTime;
  }
});
Object.defineProperty(exports, "getGeoLocationStatus", {
  enumerable: true,
  get: function get() {
    return _getGeoLocationStatus["default"];
  }
});
Object.defineProperty(exports, "getGrossAndNetValues", {
  enumerable: true,
  get: function get() {
    return _api.getGrossAndNetValues;
  }
});
Object.defineProperty(exports, "getNested", {
  enumerable: true,
  get: function get() {
    return _api.getNested;
  }
});
Object.defineProperty(exports, "getNextOrderDate", {
  enumerable: true,
  get: function get() {
    return _api.getNextOrderDate;
  }
});
Object.defineProperty(exports, "getOrderCountryCode", {
  enumerable: true,
  get: function get() {
    return _getCountryCode.getOrderCountryCode;
  }
});
Object.defineProperty(exports, "isSubscriptionOrder", {
  enumerable: true,
  get: function get() {
    return _api.isSubscriptionOrder;
  }
});
Object.defineProperty(exports, "priceCalculation", {
  enumerable: true,
  get: function get() {
    return _api.priceCalculation;
  }
});
Object.defineProperty(exports, "revenueCalculation", {
  enumerable: true,
  get: function get() {
    return _api.revenueCalculation;
  }
});
Object.defineProperty(exports, "splitAddress", {
  enumerable: true,
  get: function get() {
    return _splitAddress["default"];
  }
});
Object.defineProperty(exports, "validAddress", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validAddress;
  }
});
Object.defineProperty(exports, "validCity", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validCity;
  }
});
Object.defineProperty(exports, "validCountry", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validCountry;
  }
});
Object.defineProperty(exports, "validEmail", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validEmail;
  }
});
Object.defineProperty(exports, "validInput", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validInput;
  }
});
Object.defineProperty(exports, "validName", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validName;
  }
});
Object.defineProperty(exports, "validPhoneNumber", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validPhoneNumber;
  }
});
Object.defineProperty(exports, "validPostcode", {
  enumerable: true,
  get: function get() {
    return _inputVerification.validPostcode;
  }
});
var _splitAddress = _interopRequireDefault(require("./splitAddress"));
var _determineArrivalDay = _interopRequireDefault(require("./determineArrivalDay"));
var _getCountry = _interopRequireDefault(require("./getCountry"));
var _formatPostcode = _interopRequireDefault(require("./formatPostcode"));
var _getGeoLocationStatus = _interopRequireDefault(require("./getGeoLocationStatus"));
var _getCountryCode = require("./getCountryCode");
var _basicAuth = require("./basicAuth");
var _inputVerification = require("./inputVerification");
var _api = require("./api");
var _geoLocationStatus = require("./geoLocationStatus");
var _geoLocationHeaders = require("./geoLocationHeaders");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }