"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = validEmail;
var _inputLimits = _interopRequireDefault(require("./inputLimits"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function validEmail(emailEntry) {
  // https://medium.com/@jgratereaux/validate-emails-with-regular-expressions-2636ce08ddc2
  var regexEmailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  return regexEmailValidator.test(emailEntry) && emailEntry.length <= _inputLimits["default"].email && emailEntry.length > _inputLimits["default"].emailMin;
}