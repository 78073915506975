"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ffReactClientSdk = require("@harnessio/ff-react-client-sdk");
var getPayPalFlag = function getPayPalFlag() {
  return (0, _ffReactClientSdk.useFeatureFlag)('DTC_PayPal', false);
};
var _default = exports["default"] = getPayPalFlag;