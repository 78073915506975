"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = validPhoneNumber;
var _inputLimits = _interopRequireDefault(require("./inputLimits"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function validPhoneNumber(phoneNumber) {
  // checks that phone number is between 9 and 12 numbers and can include special characters
  return phoneNumber.length < _inputLimits["default"].phone && /^(?:\+?\D*\d){9,12}\D*$/.test(phoneNumber);
}