"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBSCRIPTION_ACTIVE_KEY = exports.SKU_NAME_KEY = exports.FIRST_SIGNUP_DATE = exports.BRAZE_LANDING_PAGE_SLUGS = exports.BRAZE_BLOG_TAGS = exports.BRAZE_BLOG_SLUGS = exports.BRAZE_BLOG_CATEGORIES = exports.BRAZE_BENEFITS_ANSWERS = void 0;
var SKU_NAME_KEY = exports.SKU_NAME_KEY = 'sku_name';
var SUBSCRIPTION_ACTIVE_KEY = exports.SUBSCRIPTION_ACTIVE_KEY = 'subscription_active';
var FIRST_SIGNUP_DATE = exports.FIRST_SIGNUP_DATE = 'first_signup_date';
var BRAZE_BLOG_SLUGS = exports.BRAZE_BLOG_SLUGS = 'blog_slugs';
var BRAZE_BLOG_CATEGORIES = exports.BRAZE_BLOG_CATEGORIES = 'blog_categories';
var BRAZE_BLOG_TAGS = exports.BRAZE_BLOG_TAGS = 'blog_tags';
var BRAZE_BENEFITS_ANSWERS = exports.BRAZE_BENEFITS_ANSWERS = 'benefits_quiz';
var BRAZE_LANDING_PAGE_SLUGS = exports.BRAZE_LANDING_PAGE_SLUGS = 'page_slugs';