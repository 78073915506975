"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gbOrXi = gbOrXi;
exports.getCountryCode = getCountryCode;
exports.getOrderCountryCode = getOrderCountryCode;
var _i18nIsoCountries = _interopRequireDefault(require("i18n-iso-countries"));
var _en = _interopRequireDefault(require("i18n-iso-countries/langs/en.json"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
// Google Maps Country Codes: https://developers.google.com/custom-search/docs/xml_results_appendices#countryCodes
// i18n-iso-countries: https://github.com/michaelwittig/node-i18n-iso-countries

// TODO: Refactor to improve naming (ie: get 3166-1 country codes)
function getCountryCode(country) {
  var formattedCountry = country.trim().toLowerCase();
  _i18nIsoCountries["default"].registerLocale(_en["default"]);
  return _i18nIsoCountries["default"].getAlpha2Code(formattedCountry, 'en');
}
function gbOrXi(countryCode, postcode) {
  if (postcode == null) {
    return countryCode;
  }
  if (countryCode === 'GB') {
    if (postcode[0] === 'B' && postcode[1] === 'T') {
      return 'XI';
    }
    return 'GB';
  }
  return countryCode;
}

// TODO: remove after v1 checkout is gone
function getOrderCountryCode(zipcode, locale, useLocale, country) {
  var localeCountryCode;
  if (useLocale && locale.length === 5) {
    // get country code from locale
    localeCountryCode = locale.substring(3, 5);
  } else {
    // get country code from entered country
    localeCountryCode = getCountryCode(country);
  }

  // if code is GB, determine if need to pass GB or XI (northern ireland)
  // this is only needed for sending data for orders
  return gbOrXi(localeCountryCode, zipcode);
}