"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getCountry;
var _i18nIsoCountries = _interopRequireDefault(require("i18n-iso-countries"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
// library: https://github.com/michaelwittig/node-i18n-iso-countries
function getCountry(countryCode) {
  _i18nIsoCountries["default"].registerLocale(require('i18n-iso-countries/langs/en.json')); // eslint-disable-line
  if (countryCode === 'XI') {
    countryCode = 'GB'; // eslint-disable-line
  }
  return _i18nIsoCountries["default"].getName(countryCode, 'en');
}