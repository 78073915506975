"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "INPUT_LIMITS", {
  enumerable: true,
  get: function get() {
    return _inputLimits["default"];
  }
});
Object.defineProperty(exports, "validAddress", {
  enumerable: true,
  get: function get() {
    return _addressVerification.validAddress;
  }
});
Object.defineProperty(exports, "validCity", {
  enumerable: true,
  get: function get() {
    return _addressVerification.validCity;
  }
});
Object.defineProperty(exports, "validCountry", {
  enumerable: true,
  get: function get() {
    return _addressVerification.validCountry;
  }
});
Object.defineProperty(exports, "validEmail", {
  enumerable: true,
  get: function get() {
    return _emailVerification["default"];
  }
});
Object.defineProperty(exports, "validInput", {
  enumerable: true,
  get: function get() {
    return _verification["default"];
  }
});
Object.defineProperty(exports, "validName", {
  enumerable: true,
  get: function get() {
    return _nameVerification["default"];
  }
});
Object.defineProperty(exports, "validPhoneNumber", {
  enumerable: true,
  get: function get() {
    return _phoneVerification["default"];
  }
});
Object.defineProperty(exports, "validPostcode", {
  enumerable: true,
  get: function get() {
    return _addressVerification.validPostcode;
  }
});
var _emailVerification = _interopRequireDefault(require("./emailVerification"));
var _nameVerification = _interopRequireDefault(require("./nameVerification"));
var _phoneVerification = _interopRequireDefault(require("./phoneVerification"));
var _verification = _interopRequireDefault(require("./verification"));
var _inputLimits = _interopRequireDefault(require("./inputLimits"));
var _addressVerification = require("./addressVerification");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }