"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateDiscount = calculateDiscount;
exports.getGrossAndNetValues = exports.getDateWithZeroTime = exports.getDateFromISO = exports.getCurrencyCodeByLocale = exports.getCurrencyCode = exports.extractTextFromURL = void 0;
exports.getNested = getNested;
exports.getNextOrderDate = void 0;
exports.isSubscriptionOrder = isSubscriptionOrder;
exports.priceCalculation = priceCalculation;
exports.revenueCalculation = revenueCalculation;
var _constants = require("./constants");
function getNested(obj) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  // eslint-disable-next-line no-shadow
  return args.reduce(function (obj, level) {
    return obj && obj[level];
  }, obj);
}
function isSubscriptionOrder(orderOrigin) {
  return [_constants.INITIAL, _constants.AUTORENEWAL, _constants.FULFILLMENT].includes(orderOrigin);
}
var validNumber = function validNumber(data) {
  return data !== undefined && Number.isInteger(data);
};
function priceCalculation(centAmount, decimalPlaces) {
  // TODO: expand and better error handling
  if (!validNumber(centAmount) || !validNumber(decimalPlaces)) {
    return 0;
  }
  var denominator = Math.pow(10, decimalPlaces);
  return centAmount / denominator;
}
function calculateDiscount(cart, cartDiscount) {
  var CartDiscountType = {
    Fixed: 'fixed',
    Absolute: 'absolute',
    Relative: 'relative'
  };

  // if cart discount is an absolute dollar amount off
  if (cartDiscount.value.type === CartDiscountType.Absolute) {
    return cartDiscount.value.money[0].centAmount * 0.01;
  }
  var subtotal = cart.totalPrice.centAmount / Math.pow(10, cart.totalPrice.fractionDigits);

  // if cart discount is a fixed dollar amount
  if (cartDiscount.value.type === CartDiscountType.Fixed) {
    return subtotal - cartDiscount.value.money[0].centAmount * 0.01;
  }

  // if cart discount is a relative percentage off
  if (cartDiscount.value.type === CartDiscountType.Relative) {
    var relativeDiscount = subtotal * cartDiscount.value.permyriad * 0.0001;
    return Math.round(relativeDiscount * 100) / 100;
  }
  return 0;
}
var getGrossAndNetValues = exports.getGrossAndNetValues = function getGrossAndNetValues(order, taxDenominator) {
  var _order$lineItems$0$di;
  var grossAmountExcludingDiscountCentAmount = order.lineItems[0].price.value.centAmount;
  var grossAmountExcludingDiscountFractionDigits = order.lineItems[0].price.value.fractionDigits;
  var grossAmountExcludingDiscount = priceCalculation(grossAmountExcludingDiscountCentAmount, grossAmountExcludingDiscountFractionDigits);
  var netAmountExcludingDiscount = priceCalculation(Math.round(grossAmountExcludingDiscountCentAmount / taxDenominator), grossAmountExcludingDiscountFractionDigits);
  var isDiscountApplied = ((_order$lineItems$0$di = order.lineItems[0].discountedPricePerQuantity) === null || _order$lineItems$0$di === void 0 ? void 0 : _order$lineItems$0$di[0]) !== undefined;
  var grossDiscountAmount = 0;
  var netDiscountAmount = 0;
  if (isDiscountApplied) {
    var grossDiscountCentAmount = order.lineItems[0].discountedPricePerQuantity[0].discountedPrice.includedDiscounts[0].discountedAmount.centAmount;
    var grossDiscountFractionDigits = order.lineItems[0].discountedPricePerQuantity[0].discountedPrice.includedDiscounts[0].discountedAmount.fractionDigits;
    grossDiscountAmount = priceCalculation(grossDiscountCentAmount, grossDiscountFractionDigits);
    netDiscountAmount = priceCalculation(Math.round(grossDiscountCentAmount / taxDenominator), grossDiscountFractionDigits);
  }
  return {
    grossAmountExcludingDiscount: grossAmountExcludingDiscount,
    netAmountExcludingDiscount: netAmountExcludingDiscount,
    grossDiscountAmount: grossDiscountAmount,
    netDiscountAmount: netDiscountAmount
  };
};
function revenueCalculation(netAmount, orderOrigin, parentOrder, taxDenominator) {
  if (orderOrigin === _constants.INITIAL || orderOrigin === _constants.FULFILLMENT) {
    var _getGrossAndNetValues = getGrossAndNetValues(parentOrder, taxDenominator),
      netAmountExcludingDiscount = _getGrossAndNetValues.netAmountExcludingDiscount,
      netDiscountAmount = _getGrossAndNetValues.netDiscountAmount;
    var parentOrderAttributes = getNested(parentOrder, 'lineItems', 0, 'variant', 'attributesRaw');
    var prepaidMonthsAttribute = parentOrderAttributes != null && parentOrderAttributes.find(function (attr) {
      return attr.name === 'months-prepaid';
    });
    var prepaidMonths = prepaidMonthsAttribute ? prepaidMonthsAttribute.value : 0;
    var revenueSplit = (netAmountExcludingDiscount - netDiscountAmount) / (1 + prepaidMonths);
    return Math.round(revenueSplit * 100) / 100;
  }
  if (orderOrigin === _constants.AUTORENEWAL) {
    return netAmount;
  }
  return 0;
}
var getCurrencyCode = exports.getCurrencyCode = function getCurrencyCode(countryCode) {
  if (countryCode === 'GB' || countryCode === 'XI') {
    return 'GBP';
  }
  return null;
};
var getCurrencyCodeByLocale = exports.getCurrencyCodeByLocale = function getCurrencyCodeByLocale(locale) {
  if (locale.toLowerCase() === 'en-GB'.toLocaleLowerCase()) {
    return 'GBP';
  }
  return null;
};
var getDateFromISO = exports.getDateFromISO = function getDateFromISO(dateString) {
  var timeZoneOffset = '-08:00';
  if (dateString.length === 14) {
    // Long date string (YYYYMMDDHHmmss)
    var year = dateString.slice(0, 4);
    var month = dateString.slice(4, 6);
    var day = dateString.slice(6, 8);
    var hour = dateString.slice(8, 10);
    var minute = dateString.slice(10, 12);
    var second = dateString.slice(12, 14);
    return new Date("".concat(year, "-").concat(month, "-").concat(day, "T").concat(hour, ":").concat(minute, ":").concat(second).concat(timeZoneOffset)).toISOString();
  }
  if (dateString.length === 8) {
    // Short date string (YYYYMMDD)
    var _year = dateString.slice(0, 4);
    var _month = dateString.slice(4, 6);
    var _day = dateString.slice(6, 8);
    return new Date("".concat(_year, "-").concat(_month, "-").concat(_day, "T00:00:00").concat(timeZoneOffset)).toISOString();
  }
  throw new Error('Invalid date string format');
};

// Fix for next order date with different shipment frequency since we do not store this
// info, until we deprecate this repo
var getNextOrderDate = exports.getNextOrderDate = function getNextOrderDate() {
  var productSku = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var TWO_WEEK_PLAN_SKU = 'GBLG01BW';
  var shipmentFrequency = productSku === TWO_WEEK_PLAN_SKU ? 14 : 28;
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + shipmentFrequency);
  return currentDate.toISOString();
};
var extractTextFromURL = exports.extractTextFromURL = function extractTextFromURL(url) {
  var extractedText = url.match(/\/([^/]+)\.[^.]+$/);
  if (extractedText && extractedText.length === 2) {
    return extractedText[1];
  }
  return 'lingo image';
};
var getDateWithZeroTime = exports.getDateWithZeroTime = function getDateWithZeroTime() {
  var offsetDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var date = new Date();
  date.setDate(date.getDate() + offsetDays);
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
};