"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var INPUT_LIMITS = {
  email: 70,
  emailMin: 6,
  name: 17,
  discountCode: 35,
  phone: 22,
  address: 30,
  addressDetails: 30,
  city: 35,
  postcode: 10,
  country: 30,
  "default": 30
};
var _default = exports["default"] = INPUT_LIMITS;