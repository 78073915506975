"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ffReactClientSdk = require("@harnessio/ff-react-client-sdk");
var getPromoCodeFlag = function getPromoCodeFlag() {
  return (0, _ffReactClientSdk.useFeatureFlag)('DTC_PromoCodes', false);
};
var _default = exports["default"] = getPromoCodeFlag;