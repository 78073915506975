"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FeatureFlagProvider", {
  enumerable: true,
  get: function get() {
    return _provider["default"];
  }
});
Object.defineProperty(exports, "getApplePayFlag", {
  enumerable: true,
  get: function get() {
    return _getApplePayFlag["default"];
  }
});
Object.defineProperty(exports, "getBrazeFlag", {
  enumerable: true,
  get: function get() {
    return _getBrazeFlag["default"];
  }
});
Object.defineProperty(exports, "getGooglePayFlag", {
  enumerable: true,
  get: function get() {
    return _getGooglePayFlag["default"];
  }
});
Object.defineProperty(exports, "getNewCheckoutFlag", {
  enumerable: true,
  get: function get() {
    return _getNewCheckoutFlag["default"];
  }
});
Object.defineProperty(exports, "getPayPalFlag", {
  enumerable: true,
  get: function get() {
    return _getPayPalFlag["default"];
  }
});
Object.defineProperty(exports, "getPromoCodeFlag", {
  enumerable: true,
  get: function get() {
    return _getPromoCodeFlag["default"];
  }
});
var _provider = _interopRequireDefault(require("./featureFlags/client/provider"));
var _getPromoCodeFlag = _interopRequireDefault(require("./featureFlags/client/flags/getPromoCodeFlag"));
var _getNewCheckoutFlag = _interopRequireDefault(require("./featureFlags/client/flags/getNewCheckoutFlag"));
var _getBrazeFlag = _interopRequireDefault(require("./featureFlags/client/flags/getBrazeFlag"));
var _getPayPalFlag = _interopRequireDefault(require("./featureFlags/client/flags/getPayPalFlag"));
var _getApplePayFlag = _interopRequireDefault(require("./featureFlags/client/flags/getApplePayFlag"));
var _getGooglePayFlag = _interopRequireDefault(require("./featureFlags/client/flags/getGooglePayFlag"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }