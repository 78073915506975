"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = determineArrivalDay;
var _countryCodes = _interopRequireDefault(require("./countryCodes.config"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function determineArrivalDay(orderDate, countryCode, carrier) {
  // This function can take in three args:
  // 1. the date that the order was created (orderDate)
  // 2. the order's countryCode (GB or XI)
  // 3. the shipping method from commerce tools query
  // as more countries are added, the config file can be
  // updated to find the current min and max delivery times

  // format the order date
  var formattedOrderDate = new Date(orderDate);
  // define a new delivery date to edit
  var deliveryDate;
  // get the hours to determine whether order placed before or after 2pm
  var hours = formattedOrderDate.getHours();
  // get the day that the order was placed (0-6, Sun-Sat)
  var day = formattedOrderDate.getDay();
  var deliveryMin = _countryCodes["default"][countryCode][carrier.trim()].deliveryMinimum;
  var deliveryMax = _countryCodes["default"][countryCode][carrier.trim()].deliveryMaximum;
  var millisecondsInADay = 24 * 60 * 60 * 1000;

  // set the estimated delivery date depending on the current day of the week and time
  if (hours < 14 && (day === 0 || day === 1 || day === 2 || day === 3 || day === 4)) {
    deliveryDate = new Date(formattedOrderDate.getTime() + deliveryMin * millisecondsInADay);
  } else if (hours >= 14 && (day === 0 || day === 1 || day === 2 || day === 3 || day === 4)) {
    deliveryDate = new Date(formattedOrderDate.getTime() + (deliveryMax - 1) * millisecondsInADay);
  } else if (day === 5) {
    deliveryDate = new Date(formattedOrderDate.getTime() + deliveryMax * millisecondsInADay);
  } else {
    deliveryDate = new Date(formattedOrderDate.getTime() + (deliveryMax - 1) * millisecondsInADay);
  }
  return deliveryDate;
}