"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BRAZE_TOPIC = exports.BRAZE_SIGNUP_SOURCE = exports.BRAZE_RECIPE_MODAL = exports.BRAZE_OUT_OF_MARKET_MODAL = exports.BRAZE_NO_PURCHASE_MODAL = exports.BRAZE_MARKETING_LANDING = exports.BRAZE_CHECKOUT = exports.BRAZE_BLOG_FOOTER = exports.BRAZE_BLOG = exports.BRAZE_BENEFITS_QUIZ_FOOTER = exports.BRAZE_BENEFITS_QUIZ = void 0;
var BRAZE_SIGNUP_SOURCE = exports.BRAZE_SIGNUP_SOURCE = 'signup_source';
var BRAZE_OUT_OF_MARKET_MODAL = exports.BRAZE_OUT_OF_MARKET_MODAL = 'out_of_market_modal';
var BRAZE_NO_PURCHASE_MODAL = exports.BRAZE_NO_PURCHASE_MODAL = 'site_footer';
var BRAZE_RECIPE_MODAL = exports.BRAZE_RECIPE_MODAL = 'metabolism101_modal';
var BRAZE_CHECKOUT = exports.BRAZE_CHECKOUT = 'checkout';
var BRAZE_BLOG = exports.BRAZE_BLOG = 'blog';
var BRAZE_TOPIC = exports.BRAZE_TOPIC = 'topic';
var BRAZE_BLOG_FOOTER = exports.BRAZE_BLOG_FOOTER = 'blog_footer';
var BRAZE_BENEFITS_QUIZ = exports.BRAZE_BENEFITS_QUIZ = 'benefits_quiz';
var BRAZE_BENEFITS_QUIZ_FOOTER = exports.BRAZE_BENEFITS_QUIZ_FOOTER = 'benefits_quiz_footer';
var BRAZE_MARKETING_LANDING = exports.BRAZE_MARKETING_LANDING = 'marketing_landing';