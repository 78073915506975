"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validAddress = validAddress;
exports.validCity = validCity;
exports.validCountry = validCountry;
exports.validPostcode = validPostcode;
var _i18nZipcodes = _interopRequireDefault(require("i18n-zipcodes"));
var _getCountryCode = require("../getCountryCode");
var _inputLimits = _interopRequireDefault(require("./inputLimits"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
// library: https://github.com/sarcadass/i18n-zipcodes

function validAddress(inputAddress) {
  var inputAddressTrimmed = inputAddress.trim();

  // if there is at least one space and not above max length
  return inputAddressTrimmed.indexOf(' ') >= 0 && inputAddressTrimmed.length > 0 && inputAddressTrimmed.length <= _inputLimits["default"].address;
}

// check that contains at least one letter (accented accepted)
function validCity(inputCity) {
  return inputCity.replace(/\s/g, '').length > 0 && /[A-zÀ-ú]+/.test(inputCity) && inputCity.length <= _inputLimits["default"].city;
}
function validPostcode(postcodeInput, country) {
  if (postcodeInput.length > _inputLimits["default"].postcode) {
    return false;
  }
  var countryCode = (0, _getCountryCode.getCountryCode)(country);
  var postCode = postcodeInput;
  postCode = postCode.trim();
  if (countryCode) {
    // this fails if country code is not in postcode validation library
    return (0, _i18nZipcodes["default"])(countryCode, postCode);
  }

  // if no validator regex or if country code doesn't exist, just make sure not empty string
  return postCode.replace(/\s/g, '').length > 0;
}
function validCountry(inputCountry) {
  var country = inputCountry.trim();
  return inputCountry.length > 0 && inputCountry.length <= _inputLimits["default"].country && (0, _getCountryCode.getCountryCode)(country) != null;
}